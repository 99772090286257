"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const utils_1 = require("src/misc/utils");
const PlatformFees = ({ platformFee, tokenInfo }) => {
    const amountText = (0, react_1.useMemo)(() => {
        if (platformFee && Number(platformFee === null || platformFee === void 0 ? void 0 : platformFee.amount) > 0) {
            return utils_1.formatNumber.format((0, utils_1.fromLamports)(Number(platformFee === null || platformFee === void 0 ? void 0 : platformFee.amount), tokenInfo.decimals));
        }
        return null;
    }, [platformFee, tokenInfo.decimals]);
    const feeBps = (0, react_1.useMemo)(() => {
        if (platformFee) {
            return (platformFee === null || platformFee === void 0 ? void 0 : platformFee.feeBps) / 100 + '%';
        }
        return null;
    }, [platformFee]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "flex items-center justify-between text-xs", children: [(0, jsx_runtime_1.jsx)("div", { className: "flex w-[50%] text-white/30", children: (0, jsx_runtime_1.jsx)("span", { children: "Platform Fee" }) }), (0, jsx_runtime_1.jsxs)("div", { className: "text-white/30", children: [amountText, " ", tokenInfo.symbol, " (", feeBps, ")"] })] }));
};
exports.default = PlatformFees;
